import resolveConfig from 'tailwindcss/resolveConfig';

export const tailwindConfig = () => {
  // Tailwind config
  return resolveConfig('./src/css/tailwind.config.js')
}

const tailwindColors = tailwindConfig().theme?.colors;

export const colors = [
tailwindColors.amber[500],
tailwindColors.blue[500],
tailwindColors.cyan[500],
tailwindColors.emerald[500],
tailwindColors.fuchsia[500],
tailwindColors.lime[500],
tailwindColors.orange[500],
tailwindColors.rose[500],
tailwindColors.sky[500],
tailwindColors.violet[500],
tailwindColors.red[500],
tailwindColors.green[500],
tailwindColors.purple[500],
tailwindColors.pink[500],
tailwindColors.indigo[500],
tailwindColors.yellow[500],
tailwindColors.gray[500],
tailwindColors.teal[500],
tailwindColors.zinc[500],
];

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) => Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);

export const formatThousands = (value) => Intl.NumberFormat('en-US', {
  maximumSignificantDigits: 3,
  notation: 'compact',
}).format(value);
